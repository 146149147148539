export const ArrowForward = (props) => {
 return (
  <svg
   {...props}
   stroke="currentColor"
   fill="currentColor"
   strokeWidth="0"
   viewBox="0 0 512 512"
   height="200px"
   width="200px"
   xmlns="http://www.w3.org/2000/svg"
  >
   <path
    fill="none"
    strokeWidth="48"
    d="m268 112 144 144-144 144m124-144H100"
   ></path>
  </svg>
 );
};
