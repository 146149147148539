import { Helmet } from "react-helmet";

export const Meta = ({
 title,
 description,
}: {
 title: string;
 description: string;
}) => {
 return (
  <Helmet>
   <title>{title}</title>
   <meta name="description" content={description} />
  </Helmet>
 );
};
