import YouTubePlayer, { YouTubeEvent } from "react-youtube";

export const SectionLanding = ({
 title,
 desc,
 videoId,
}: {
 title: string;
 desc: string;
 videoId: string;
}) => {
 const onReady = (event: YouTubeEvent) => {
  event.target.playVideo();
 };
 return (
  <div className="w-full min-h-screen  relative overflow-hidden text-white ">
   <div className=" w-[1920px] h-[1080px] -top-16 inset-x-0 absolute z-[-10] flex items-center justify-center overflow-visible  place-self-center">
    <YouTubePlayer
     iframeClassName="aspect-16/9 w-[100%] h-[100%] bg-black object-center"
     className="aspect-16/9 w-[100%] h-[100%] object-center"
     loading="lazy"
     videoId={videoId}
     opts={{
      playerVars: {
       autoplay: 1,
       mute: 1,
       controls: 0,
      },
     }}
     onEnd={onReady}
     onReady={onReady}
    />
   </div>
   <div className="w-full min-h-screen flex flex-col justify-between py-20 space-y-10 sm:space-y-20 dark-national-tech-section">
    <h1 className="mx-auto text-center sm:text-left w-[90vw] sm:w-[85vw] sm:text-[52px] text-[35px] font-bold m-0 p-0">
     {title}
    </h1>
    <div className="w-[90vw] sm:w-[85vw] mx-auto flex flex-col ">
     <p className="w-full sm:w-[80%] text-justify text-pretty tracking-tight leading-snug	break-words">
      {desc}
     </p>
    </div>
   </div>
  </div>
 );
};
