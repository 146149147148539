export const Unlock = (props) => (
 <svg
  stroke="currentColor"
  fill="currentColor"
  {...props}
  strokeWidth="0"
  viewBox="0 0 1024 1024"
  height="200px"
  width="200px"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path d="M832 464H332V240c0-30.9 25.1-56 56-56h248c30.9 0 56 25.1 56 56v68c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-68c0-70.7-57.3-128-128-128H388c-70.7 0-128 57.3-128 128v224h-68c-17.7 0-32 14.3-32 32v384c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V496c0-17.7-14.3-32-32-32zM540 701v53c0 4.4-3.6 8-8 8h-40c-4.4 0-8-3.6-8-8v-53a48.01 48.01 0 1 1 56 0z"></path>
 </svg>
);
