import i18next from "i18next";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import enInsultation from "./languages/en/insultation.json";
import trInsultation from "./languages/tr/insultation.json";

import enHome from "./languages/en/home.json";
import trHome from "./languages/tr/home.json";

import enGeneral from "./languages/en/general.json";
import trGeneral from "./languages/tr/general.json";

import enBlog from "./languages/en/blog.json";
import trBlog from "./languages/tr/blog.json";

import enNationalTech from "./languages/en/national-tech.json";
import trNationalTech from "./languages/tr/national-tech.json";

import enBistLab from "./languages/en/bist-lab.json";
import trBistLab from "./languages/tr/bist-lab.json";

import enEntrepreneur from "./languages/en/entrepreneur.json";
import trEntrepreneur from "./languages/tr/entrepreneur.json";

import enProject from "./languages/en/project.json";
import trProject from "./languages/tr/project.json";

import enAcademy from "./languages/en/academy.json";
import trAcademy from "./languages/tr/academy.json";

import enSmartCity from "./languages/en/smart-city.json";
import trSmartCity from "./languages/tr/smart-city.json";

i18next.init({
 interpolation: { escapeValue: false },
 lng: localStorage.getItem("lang") || "tr",
 resources: {
  en: {
   home: enHome,
   general: enGeneral,
   blog: enBlog,
   insultation: enInsultation,
   nationalTech: enNationalTech,
   bistlab: enBistLab,
   entrepreneur: enEntrepreneur,
   project: enProject,
   academy: enAcademy,
   smartCity: enSmartCity,
  },
  tr: {
   home: trHome,
   general: trGeneral,
   blog: trBlog,
   insultation: trInsultation,
   nationalTech: trNationalTech,
   bistlab: trBistLab,
   entrepreneur: trEntrepreneur,
   project: trProject,
   academy: trAcademy,
   smartCity: trSmartCity,
  },
 },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  //  <React.StrictMode>
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>
  //  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
