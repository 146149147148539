import "./App.css";
import { Suspense, lazy } from "react";
import { Loading } from "./sharedComponent";
import { BrowserRouter } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";

const Router = lazy(() => import("./router/router"));

function App() {
 return (
  <Suspense fallback={<Loading />}>
   <BrowserRouter>
    <ParallaxProvider>
     <Router />
    </ParallaxProvider>
   </BrowserRouter>
  </Suspense>
 );
}

export default App;
