export const Close = (props) => (
 <svg
  stroke="currentColor"
  {...props}
  fill="none"
  strokeWidth="2"
  viewBox="0 0 24 24"
  height="200px"
  width="200px"
  xmlns="http://www.w3.org/2000/svg"
 >
  <line x1="18" y1="6" x2="6" y2="18"></line>
  <line x1="6" y1="6" x2="18" y2="18"></line>
 </svg>
);
