import { Kapsul } from "../assets/icons";

export const Logo = ({ className }: { className: string }) => {
 return (
  <a rel="Ansayfaya dön" href="/">
   <Kapsul
    className={`${className} cursor-pointer`}
    alt="Kapsül Teknoloji Platformu Logo"
   />
  </a>
 );
};
