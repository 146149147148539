import { UseGeneral } from "../store";
import { ConfigProvider, Switch } from "antd";
import { MoonFilled, SunFilled } from "@ant-design/icons";

export const ThemeSwitch = () => {
 const { setTheme, theme } = UseGeneral();

 return (
  <ConfigProvider
   theme={{
    token: {
     colorPrimary: "rgb(22 23 25 / 0.3)",
    },
   }}
  >
   <Switch
    checkedChildren={<MoonFilled />}
    unCheckedChildren={<SunFilled />}
    onChange={(e: boolean) => {
     if (e) setTheme("dark");
     else setTheme("light");
    }}
    value={theme === "light" ? false : true}
   />
  </ConfigProvider>
 );
};
