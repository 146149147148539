export const OutputCard = ({
  content,
  placement,
}: {
  content: string;
  placement: string;
}) => {
  return (
    <h2
      className={`px-2 py-1 text-nowrap rounded-lg bg-ktp_red text-white text-[3vw] absolute font-bold border-8 border-double border-white select-none ${placement} `}
    >
      {content}
    </h2>
  );
};
