import { Collapse, CollapseProps, ConfigProvider } from "antd";
import { SSSArrow } from "../assets/icons";
import { UseGeneral } from "../store";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";

export const SSSQuestions = ({
 questions,
 borderColor = "#EF3B52",
}: {
 questions: CollapseProps["items"];
 borderColor?: string;
}) => {
 const { theme } = UseGeneral();
 const [t] = useTranslation("insultation");

 return (
  <div className="w-full space-y-10 sm:space-y-20">
   <h1 className="mx-auto  w-[90vw] sm:w-[85vw] sm:text-[52px] text-[35px] font-bold m-0 p-0">
    {t("sss.title")}
   </h1>
   <div className="sm:w-[85vw] w-[95vw] mx-auto   rounded-xl p-0">
    <ConfigProvider
     theme={{
      token: {
       colorText: theme === "light" ? "#000" : "#fff",
      },
      components: {
       Collapse: {
        headerBg: theme === "light" ? "#EEEDEB" : "transparent",
        colorIcon: "#BA2038",
        colorBorder: borderColor,
       },
      },
     }}
    >
     <Collapse
      size="large"
      accordion
      bordered={false}
      className=""
      expandIcon={(e: any) => {
       return (
        <SSSArrow
         className={`size-5 sm:mt-3.5 mt-1.5 fill-[${borderColor}]  ${
          e.isActive && "rotate-90"
         }`}
        />
       );
      }}
      defaultActiveKey={["1"]}
      items={questions}
     />
    </ConfigProvider>
   </div>
  </div>
 );
};

export const SSSHeader = ({ children = "" }: { children?: ReactNode }) => {
 return <h1 className="font-bold sm:text-[20px] text-[20px] ">{children}</h1>;
};
export const SSSContent = ({ children }: { children: ReactNode }) => {
 return <p className=" ">{children}</p>;
};
