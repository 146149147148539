export const FeedbackLine = ({
 content,
 className,
}: {
 content: string;
 className: string;
}) => {
 return (
  <h1 className={`mx-auto w-fit font-bold text-[24px] py-4 ${className}`}>
   {content}
  </h1>
 );
};
